<template>
  <div class="MK215">
    <div class="banner">
      <div class="title">MK 215</div>
      <div class="smallTitle">Customizable design and features</div>
      <div class="img">
        <img src="../../../assets/MK215页面配图/分组 17.png" alt="" />
      </div>
    </div>
    <div class="video">
      <video
        src="../../../assets/MK215页面配图/Compax_Mk_215_Video.mp4"
        muted
        autoplay
        loop
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="supports">
      <div class="content">
        <div v-show="supportsCon == 0">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第三页后.png" alt="" />
          </div>
          <div class="line">
            <img src="../../../assets/MK215页面配图/背面.png" alt="" />
          </div>
          <div class="leftText">
            <div class="title">Wi-Fi</div>
            <div class="title">Bluetooth</div>
          </div>
          <div class="rightText">
            <div class="title">Metal</div>
          </div>
          <div class="button" @click="supportsBtn(1)">
            <div class="icon">
              <img src="../../../assets/ico/分组 1.png" alt="" />
            </div>
            <div class="title">Front</div>
          </div>
        </div>
        <div v-show="supportsCon == 1">
          <div class="bigtitle">Supports 10 touch points</div>
          <div class="img">
            <img src="../../../assets/MK215页面配图/第三页前.png" alt="" />
          </div>
          <div class="hand">
            <img src="../../../assets/ico/hand.png" alt="" />
          </div>
          <div class="button" @click="supportsBtn(0)">
            <div class="icon">
              <img src="../../../assets/ico/分组 1.png" alt="" />
            </div>
            <div class="title">Back</div>
          </div>
        </div>
      </div>
    </div>
    <div class="printingAndQR">
      <div class="content">
        <div class="left">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第四页1.png" alt="" />
          </div>
          <div class="text">Printing</div>
        </div>
        <div class="right">
          <div class="img">
            <img src="../../../assets/MK215页面配图/第四页2.png" alt="" />
          </div>
          <div class="text">QR code Payment</div>
        </div>
      </div>
    </div>
    <div class="product">
      <div class="line">
        <img src="../../../assets/MK215页面配图/爆炸图.png" alt="" />
      </div>
      <div class="topLeft">
        <div class="text">10-point capacitive touch screen</div>
      </div>
      <div class="topRight"><div class="text">7H hardness glass</div></div>
      <div class="rightCon">
        <div class="text">Fully laminated process</div>
      </div>
      <div class="bottomRight">
        <div class="text">80mm thermal printer with</div>
        <div class="text">Socket communication for agile</div>
        <div class="text">and rapid development</div>
      </div>
      <div class="bottomLeft">
        <div class="text">Aluminum alloy</div>
        <div class="text">structurally stable and</div>
        <div class="text">lightweight</div>
      </div>
    </div>
    <div class="MK215Con">
      <div class="titleBox">
        <div class="title">MK 215</div>
      </div>
      <div class="itemBox">
        <div class="one">
          <div class="itemA">
            <div class="title">OS</div>
            <div class="text">Android 11</div>
          </div>
          <div class="itemA">
            <div class="title">Memory</div>
            <div class="text">RAM 4G</div>
            <div class="text">ROM 32GB(up to 128GB)</div>
          </div>
          <div class="itemB">
            <div class="title">Master Control</div>
            <div class="text">Rock Chips RK3566</div>
            <div class="text">ARM Cortex-A55 4 Cores 1.8GHz</div>
          </div>
          <div class="itemA">
            <div class="title">Printer</div>
            <div class="text">80mm High-speed printers</div>
          </div>
          <div class="itemA">
            <div class="title">LCD screen</div>
            <div class="text">21.5-inch</div>
            <div class="text">high-definition screen</div>
          </div>
          <div class="itemA">
            <div class="title">Touch Screen</div>
            <div class="text">10 point capacitive screen</div>
          </div>
          <div class="itemA">
            <div class="title">Wireless</div>
            <div class="text">BT5.0</div>
          </div>
          <div class="itemA">
            <div class="title">Color</div>
            <div class="text">Space Black / Space Gray</div>
            <div class="text">Midnight</div>
          </div>
          <div class="itemA">
            <div class="title">Housing</div>
            <div class="text">Anodised Aluminium</div>
          </div>
          <div class="itemC">
            <div class="title">Interfaces</div>
            <div class="text">DC12Vpower port*1；</div>
            <div class="text">RJ9*1；</div>
            <div class="text">HDMI*1；</div>
            <div class="text">Audio/MIC*1；</div>
            <div class="text">USB2.0*3 (One of them is a debugging port)；</div>
            <div class="text">USB3.0*1；</div>
            <div class="text">Ethernet*1；</div>
            <div class="text">Cash drawer*1</div>
          </div>
          <div class="itemD">
            <div class="leftText">
              <div class="title">Dimensions (L*W*H)</div>
              <div class="text">366*230*1467mm</div>
            </div>
            <div class="rightImg">
              <div class="top textsmall">366mm</div>
              <div class="left textsmall">1467mm</div>
              <div class="right textsmall">230mm</div>
              <div class="img">
                <img src="../../../assets/MK215页面配图/MK215.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supportsCon: 0 //0后  1前
    }
  },
  methods: {
    supportsBtn(val) {
      this.supportsCon = val
    }
  }
}
</script>

<style lang="less" scoped>
.MK215 {
  .banner {
    position: relative;
    padding-top: 14px;
    height: 521px;
    background: url('../../../assets/MK215页面配图/第一页动图.gif') no-repeat
      center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .img {
      position: absolute;
      top: 169px;
      left: 34px;
      width: 322px;
      height: 99px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .supports {
    display: flex;
    justify-content: center;
    height: 287.38px;
    background: rgba(0, 0, 0, 1);
    .content {
      position: relative;
      width: 363.23px;
      height: 287.38px;
      .bigtitle {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 700;
        line-height: 23.17px;
      }
      .title {
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 700;
        line-height: 17.38px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .img {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .leftText {
        text-align: center;
        width: 83px;
        position: absolute;
        z-index: 2;
        top: 115.9px;
        left: 33px;
      }
      .rightText {
        width: 70px;
        text-align: center;
        position: absolute;
        z-index: 2;
        top: 165px;
        left: 283px;
      }
      .button {
        cursor: pointer;
        display: flex;
        align-items: center;
        z-index: 2;
        position: absolute;
        top: 18.73px;
        left: 294px;
        .icon {
          display: flex;
          // cursor: pointer;
          margin-right: 6px;
          width: 21.93px;
          height: 22.75px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .line {
        z-index: 2;
        position: absolute;
        width: 242.92px;
        height: 83.68px;
        top: 78.27px;
        left: 75.04px;
      }
      .hand {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45.81px;
        height: 70px;
      }
    }
  }
  .printingAndQR {
    background: rgba(0, 0, 0, 1);
    height: 289.62px;
    display: flex;
    justify-content: center;
    .content {
      width: 343.73px;
      padding-top: 40.62px;
      display: flex;
      justify-content: space-between;
      .text {
        color: rgba(255, 255, 255, 1);
        line-height: 40px;
        font-size: 12px;
        font-weight: 600;
      }
      .left {
        width: 166.12px;
        text-align: center;
        .img {
          border: 2px solid rgba(128, 128, 128, 1);
          border-radius: 20px;
          width: 100%;
          height: 181.24px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .right {
        width: 166.12px;
        text-align: center;
        .img {
          border: 2px solid rgba(128, 128, 128, 1);
          border-radius: 20px;
          width: 100%;
          height: 181.24px;
          img {
            border-radius: 20px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .product {
    position: relative;
    height: 389px;
    background: url('../../../assets/MK215页面配图/第五页 爆炸图、.png')
      no-repeat center;
    background-size: cover;
    .line {
      width: 314.82px;
      height: 267.98px;
      position: absolute;
      top: 33.86px;
      left: 32.93px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
      // height: 46px;
      line-height: 14.48px;
    }
    .topLeft {
      position: absolute;
      top: 11.49px;
      left: 22px;
    }
    .topRight {
      position: absolute;
      top: 11.49px;
      right: 23px;
    }
    .rightCon {
      position: absolute;
      top: 192.34px;
      right: 23.3px;
    }
    .bottomRight {
      text-align: end;
      position: absolute;
      top: 305.08px;
      right: 23px;
    }
    .bottomLeft {
      position: absolute;
      top: 305.08px;
      left: 22px;
    }
  }
  .MK215Con {
    background: rgba(0, 0, 0, 1);
    .titleBox {
      margin-left: 30px;
      padding: 35px 0px 9px 0px;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 53px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 13px;
      padding: 0px 20px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 14.48px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 238px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        position: relative;
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 181px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
        .textsmall {
          font-size: 8px;
          color: rgba(255, 255, 255, 1);
        }
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 21.39px;
          right: 76.39px;
          .img {
            width: 79.47px;
            height: 158.36px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            position: absolute;
            top: -4px;
            left: 71.14px;
          }
          .left {
            position: absolute;
            left: -25px;
            top: 68.31px;
          }
          .right {
            width: fit-content;
            position: absolute;
            bottom: 5px;
            left: 71.14px;
          }
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          width: 700px;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
      }
    }
  }
}
</style>
